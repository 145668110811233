import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '../locales/en';
import de from '../locales/de';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('lang'),
  fallbackLocale: 'de',
  messages: { en, de },
});
