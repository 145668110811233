<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="4">

        <v-card>

          <v-card-title>{{ $t('auth.title') }}</v-card-title>

          <v-card-text>

            <v-alert
              v-model="authError"
              color="warning"
              border="left"
              icon="mdi-twitter"
              transition="slide-x-transition"
              colored-border
              dismissible
            >{{ $t('auth.errorMsg') }}</v-alert>

            <v-form
              v-model="isValid"
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="login"
                :rules="[v => !!v || this.$t('auth.loginRuleMsg')]"
                :label="$t('auth.loginLabel')"
                required
                @keydown.enter="submit"
              />

              <v-text-field
                v-model="password"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[v => !!v || this.$t('auth.passwordRuleMsg')]"
                :type="showPass ? 'text' : 'password'"
                :label="$t('auth.passwordLabel')"
                required
                @click:append="showPass = !showPass"
                @keydown.enter="submit"
              />

            </v-form>

          </v-card-text>

          <v-card-actions>
            <v-btn
              block
              outlined
              @click="submit"
            >{{ $t('auth.submitBtn') }}</v-btn>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AuthForm',
  data: () => ({
    isValid: true,
    login: '',
    password: '',
    showPass: false,
  }),
  computed: mapState(['authError']),
  methods: {
    ...mapActions(['authLogin']),

    submit() {
      if (!this.$refs.form.validate()) return;
      this.authLogin({ login: this.login, password: this.password });
    },
  },
};
</script>
