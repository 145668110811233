import en from 'vuetify/lib/locale/en';

export default {
  $vuetify: {
    ...en,
  },

  general: {
    language: 'Language',
  },

  auth: {
    title: 'Please log in to continue',
    loginLabel: 'Login',
    passwordLabel: 'Password',
    loginRuleMsg: 'Login required',
    passwordRuleMsg: 'Password required',
    submitBtn: 'Submit',
    errorMsg: 'Wrong login or password',
    logout: 'Log out',
  },

  filters: {
    portal: 'Portal',
    portalWarning: 'Portal is required',
    vehicleClass: 'Vehicle',
    region: 'Region',
    rentalPeriod: 'Rental period',
    pickupDate: 'Pickup date',
    priceHistoryFrom: 'Price history from',
    priceHistoryTo: 'Price history to',
    rentalPeriods: {
      day1: '1 Day',
      day3: '3 Days',
      day7: '7 Days',
      day14: '14 Days',
      weekends: 'Weekends',
    },
  },

  buttons: {
    reportBtn: 'View report',
    resetBtn: 'Reset',
    resetSelecting: 'Reset selection',
    moreBtn: 'More',
  },

  tabs: {
    tableTab: 'Table',
    chartByVehicleTab: 'Figure: price per vehicle and region',
    chartByRegionTab: 'Figure: price comparison vehicle classes per region',
    chartByPriceHistoryTab: 'Figure: price history',
  },

  tableTab: {
    subheader: 'Pay now / Pay later, all prices in €',
    exportTooltip: 'Export to excel',
    headers: {
      vehicleClass: 'Vehicle group',
      rentalPeriod: 'Rental period',
      pickupDate: 'Pickup date',
      medianPayNow: 'Median pay now',
      medianPayLater: 'Median pay later',
      kw: 'CW',
    },
  },

  chartByPriceHistoryTab: {
    warning: 'Please select at least one portal to display this figure',
    xAxis: 'Calendar week',
    yAxis: 'Average price in €',
    legend: 'Price history',
  },

  chartByVehicleTab: {
    warning: 'Please select only one vehicle class and only one region to display this figure',
    xAxis: 'Portals',
    yAxis: 'Price in €',
    legend: 'Average price for {vehicle}, {region}',
    averagePayNow: 'Average price pay now',
    averagePayLater: 'Average price pay later',
    medianPayNow: 'Median price - pay now: {value}',
    medianPayLater: 'Median price - pay later: {value}',
  },

  chartByRegionTab: {
    warning: 'Please select only one region and only one rental period to display this chart',
    subheader: 'The calculation of the price recommendation is based on the median',
    xAxis: 'Vehicle class',
    yAxis: 'Price in €',
    legend: 'Summary price recommendations: {region}, {period}',
    plotBandLabel: 'Price recommendation (*):<br/> {price} €',
  },
};
