import de from 'vuetify/lib/locale/de';

export default {
  $vuetify: {
    ...de,
  },

  general: {
    language: 'Sprache',
  },

  auth: {
    title: 'Bitte einloggen zum Fortfahren',
    loginLabel: 'Login',
    passwordLabel: 'Passwort',
    loginRuleMsg: 'Login erforderlich',
    passwordRuleMsg: 'Passwort erforderlich',
    submitBtn: 'Einloggen',
    errorMsg: 'Login oder Passwort falsch',
    logout: 'Ausloggen',
  },

  filters: {
    portal: 'Portal',
    portalWarning: 'Portal ist erforderlich',
    vehicleClass: 'Fahrzeugklasse',
    region: 'Region',
    rentalPeriod: 'Anmietzeitraum',
    pickupDate: 'Abholdatum',
    priceHistoryFrom: 'Preishistorie von',
    priceHistoryTo: 'Preishistorie bis',
    rentalPeriods: {
      day1: '1 Tag',
      day3: '3 Tage',
      day7: '7 Tage',
      day14: '14 Tage',
      weekends: 'Wochenende',
    },
  },

  buttons: {
    reportBtn: 'Report anzeigen',
    resetBtn: 'Zurücksetzen',
    resetSelecting: 'Auswahl zurücksetzen',
    moreBtn: 'Mehr',
  },

  tabs: {
    tableTab: 'Tabelle',
    chartByVehicleTab: 'Graph: Preis je Fahrzeug / Region',
    chartByRegionTab: 'Abbildung: Preisvergleich Fahrzeugklassen je Region',
    chartByPriceHistoryTab: 'Abbildung zur Preishistorie',
  },

  tableTab: {
    subheader: 'Sofort bezahlen / Später bezahlen, alle Preise in €',
    exportTooltip: 'Nach Excel exportieren',
    headers: {
      vehicleClass: 'Fahrzeuggruppe',
      rentalPeriod: 'Anmietzeitraum',
      pickupDate: 'Abholdatum',
      medianPayNow: 'Medianpreis - Sofort bezahlen',
      medianPayLater: 'Medianpreis - Später bezahlen',
      kw: 'KW',
    },
  },

  chartByPriceHistoryTab: {
    warning: 'Bitte wählen Sie mindestens ein Portal aus, um diese Zahl anzuzeigen',
    xAxis: 'Kalenderwoche',
    yAxis: 'mittlerer Preis in €',
    legend: 'Preishistorie',
  },

  chartByVehicleTab: {
    warning: 'Bitte wählen Sie nur eine Fahrzeugklasse und nur eine Region aus, um dieses Bild anzuzeigen',
    xAxis: 'Portale',
    yAxis: 'Preis in €',
    legend: 'Durchschnittspreise für {vehicle}, {region}',
    averagePayNow: 'Durchschnittspreis - Sofort bezahlen',
    averagePayLater: 'Durchschnittspreis - Später bezahlen',
    medianPayNow: 'Medianpreis - Sofort bezahlen: {value}',
    medianPayLater: 'Medianpreis - Später bezahlen: {value}',
  },

  chartByRegionTab: {
    warning: 'Bitte wählen Sie nur eine Region und nur einen Mietzeitraum aus, um dieses Bild anzuzeigen',
    subheader: 'Für die Ermittlung der Preisempfehlung wurde der Median verwendet',
    xAxis: 'Fahrzeugklasse',
    yAxis: 'Preis in €',
    legend: 'Zusammenfassung Preisvorschläge: {region}, {period}',
    plotBandLabel: 'Preisempfehlung (*):<br/> {price} €',
  },
};
