<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <v-app-bar-nav-icon/>
    <v-toolbar-title>Mietauto</v-toolbar-title>
    <v-spacer/>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>

            <v-list-item-icon>
              <v-icon>
                mdi-web
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              {{ $t('general.language') }}
            </v-list-item-content>

            <v-list-item-action>
              <v-btn-toggle
                v-model="lang"
                dense
                light
                mandatory
              >
                <v-btn
                  v-for="item in languages"
                  :key="item"
                  :value="item"
                  text
                >{{ item }}</v-btn>
              </v-btn-toggle>
            </v-list-item-action>

          </v-list-item>

          <v-divider v-if="userLogged"/>

          <v-list-item v-if="userLogged">

            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              {{ userName }}
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                elevation="0"
                @click="logout"
              >{{ $t('auth.logout') }}</v-btn>
            </v-list-item-action>

          </v-list-item>

        </v-list>

        <v-divider></v-divider>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AppBar',
  data: () => ({
    languages: ['de', 'en'],
    lang: null,
    menu: false,
  }),
  computed: mapState(['userName', 'userLogged']),
  watch: {
    lang(value) {
      this.$root.$i18n.locale = value;
      localStorage.setItem('lang', value);
    },
  },
  created() {
    this.lang = this.languages.includes(
      localStorage.getItem('lang'),
    ) ? localStorage.getItem('lang') : 'de';
  },
  methods: mapActions(['logout']),
};
</script>
