<template>
  <v-app>
    <app-bar/>

    <v-main>
      <Main v-if="userLogged" />
      <AuthForm v-else/>
    </v-main>

    <v-snackbar
      v-model="alert"
      :color="isError ? 'error' : 'success'"
      multi-line
      top
    >
      <div class="text-center">
        <span class="text-left pr-4" v-if="isError">
          <p class="title">
            Oops! Server return: {{ status }}
          </p>
          <p>There is in body:<br><code>{{ msg }}</code></p>
        </span>
        <span v-else class="mr-2">{{ status }}</span>
        <v-btn dark text @click="alert = false" class="mx-auto">Close</v-btn>
      </div>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AppBar from './components/AppBar.vue';
import Main from './components/Main.vue';
import AuthForm from './components/AuthForm.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    Main,
    AuthForm,
  },
  computed: {
    ...mapState(['isError', 'status', 'msg', 'userLogged']),

    alert: {
      get() {
        return !!this.status;
      },
      set(v) {
        if (!v) { this.$store.dispatch('hideAlert'); }
      },
    },
  },
  created() {
    this.getUser();
  },
  methods: mapActions(['getUser']),
};
</script>
