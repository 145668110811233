<template>
  <v-container fluid>
    <v-row>
      <v-col>

        <v-card>
          <v-card-text>
            <v-form
              v-model="isValid"
              ref="form"
              lazy-validation
            >

              <v-row>
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="filters.portals"
                    :items="portals"
                    :loading="arePortalsLoading"
                    :label="$t('filters.portal')"
                    :rules="[v => !!v.length || $t('filters.portalWarning')]"
                    required
                    prepend-icon="mdi-label"
                    chips
                    clearable
                    dense
                    multiple
                    small-chips
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="filters.vehicles"
                    :items="vehicles"
                    :label="$t('filters.vehicleClass')"
                    prepend-icon="mdi-book-variant"
                    chips
                    clearable
                    dense
                    multiple
                    small-chips
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="filters.locations"
                    :items="regions"
                    :label="$t('filters.region')"
                    prepend-icon="mdi-crosshairs-gps"
                    chips
                    clearable
                    dense
                    multiple
                    small-chips
                  />
                </v-col>

              </v-row>

              <v-row>

                <v-col cols="12" md="3">
                  <v-combobox
                    v-model="filters.rentalPeriods"
                    :items="rentalPeriods"
                    :label="$t('filters.rentalPeriod')"
                    item-text="text"
                    item-value="value"
                    prepend-icon="mdi-av-timer"
                    chips
                    clearable
                    dense
                    multiple
                    small-chips
                  >
                    <template #item="{ item }">
                      {{ $t(item.text) }}
                    </template>
                    <template #selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        v-bind="data.attrs"
                        @click:close="data.parent.selectItem(data.item)"
                      >{{ $t(data.item.text) }}</v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="pickupMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.pickupDate"
                        :label="$t('filters.pickupDate')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        dense
                      />
                    </template>
                    <v-date-picker
                      v-model="filters.pickupDate"
                      :locale="$i18n.locale"
                      @input="pickupMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="menuHistoryFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.priceHistoryFrom"
                        :label="$t('filters.priceHistoryFrom')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        dense
                      />
                    </template>
                    <v-date-picker
                      v-model="filters.priceHistoryFrom"
                      :locale="$i18n.locale"
                      @input="menuHistoryFrom = false"
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3">
                  <v-menu
                    v-model="menuHistoryTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.priceHistoryTo"
                        :label="$t('filters.priceHistoryTo')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        dense
                      />
                    </template>
                    <v-date-picker
                      v-model="filters.priceHistoryTo"
                      :locale="$i18n.locale"
                      @input="menuHistoryTo = false"
                    />
                  </v-menu>
                </v-col>

              </v-row>

            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-badge
              :value="filtersUpdated"
              color="warning"
              transition="fab-transition"
              bordered
              dot
              overlap
            >
              <v-btn
                color="primary"
                depressed
                @click="debounceRunSearchQuery"
              >{{ $t('buttons.reportBtn') }}</v-btn>
            </v-badge>
            <v-spacer/>
            <v-btn
              color="secondary"
              outlined
              @click="resetForm"
            >{{ $t('buttons.resetBtn') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>

    <v-row class="text-center">
      <v-col>
        <v-tabs
          v-model="tab"
        >
          <v-tab
            v-for="item in tabs"
            :key="item.id"
            :href="`#${item.id}`"
            class="text-normal"
          >
            {{ $t(item.name) }}
          </v-tab>

          <v-menu
            v-if="moreTabs.length"
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                text
                class="align-self-center text-capitalize mr-4"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('buttons.moreBtn') }}
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>

            <v-list class="grey lighten-3">
              <v-list-item
                v-for="item in moreTabs"
                :key="item.id"
                @click="addTab(item)"
              >
                {{ $t(item.name) }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, i) in tabs"
            :key="i"
            :id="item.id"
          >
            <component :is="item.component" />
          </v-tab-item>
        </v-tabs-items>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'Main',
  data() {
    return {
      rentalPeriods: [{ text: 'filters.rentalPeriods.day1', value: '1 Tag' },
        { text: 'filters.rentalPeriods.day3', value: '3 Tage' },
        { text: 'filters.rentalPeriods.day7', value: '7 Tage' },
        { text: 'filters.rentalPeriods.day14', value: '14 Tage' },
        { text: 'filters.rentalPeriods.weekends', value: 'WE' }],
      pickupMenu: false,
      menuHistoryFrom: false,
      menuHistoryTo: false,
      tabs: [],
      tabsComponents: [
        { name: 'tabs.tableTab', id: 'table', component: () => import('./Table.vue') },
        {
          name: 'tabs.chartByVehicleTab',
          id: 'chart-by-vehicle',
          component: () => import('./ChartByVehicle.vue'),
        },
        {
          name: 'tabs.chartByRegionTab',
          id: 'chart-by-region',
          component: () => import('./ChartByRegion.vue'),
        },
        {
          name: 'tabs.chartByPriceHistoryTab',
          id: 'chart-by-history',
          component: () => import('./ChartByPriceHistory.vue'),
        },
      ],
      tab: null,
      cleanFilters: {},
      filtersUpdated: false,
      filters: {},
      isValid: true,
    };
  },
  computed: {
    ...mapState(['search',
      'portals', 'arePortalsLoading',
      'vehicles', 'areVehiclesLoading',
      'regions', 'areRegionsLoading']),

    debounceRunSearchQuery() {
      return debounce(this.getData, this.$store.getters.APITimeout);
    },
    moreTabs() {
      const more = [];
      this.tabsComponents.forEach((item) => {
        if (!this.tabs.filter((tab) => tab.id === item.id).length) {
          more.push(item);
        }
      });
      return more;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filtersUpdated = true;
      },
    },
  },
  created() {
    this.tabs.push(this.tabsComponents[0]);
    this.cleanFilters = { ...this.search };
    this.filters = { ...this.search };
  },
  mounted() {
    this.getPortals();
    this.getVehicles();
    this.getRegions();
    setTimeout(() => {
      this.filtersUpdated = false;
    }, 50);
  },
  methods: {
    ...mapActions(['getPortals', 'getVehicles', 'getRegions']),

    getData() {
      if (!this.$refs.form.validate()) return;
      this.$store.commit('changeState', { search: { ...this.filters } });
      this.$root.$emit(this.tab);
      this.filtersUpdated = false;
    },
    addTab(item) {
      this.tabs.push(item);
      this.tab = item.id;
    },
    resetForm() {
      this.filters = { ...this.cleanFilters };
    },
  },
};
</script>

<style lang="sass">
.v-tab.text-normal
  text-transform: none!important
</style>
