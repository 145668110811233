import axios from '@/plugins/axios';

const pickOutParams = (search) => ({
  portals: search.portals,
  vehicles: search.vehicles,
  locations: search.locations,
  rental_periods: search.rentalPeriods.map(({ value }) => value),
  pickup_date: search.pickupDate ? `${search.pickupDate}T00:00:00Z` : null,
  price_history_from: search.priceHistoryFrom
    ? `${search.priceHistoryFrom}T00:00:00Z` : null,
  price_history_to: search.priceHistoryTo
    ? `${search.priceHistoryTo}T00:00:00Z` : null,
});

const errorHandler = (error, dispatch) => {
  const { status, response } = error.request;
  dispatch('setAlert', {
    isError: true,
    status,
    msg: response,
  });
};

export default {
  state: {
    search: {
      portals: [],
      vehicles: [],
      locations: [],
      rentalPeriods: [],
      pickupDate: null,
      priceHistoryFrom: null,
      priceHistoryTo: null,
    },

    isReportLoading: false,
    reportData: [],
    reportTotalRecords: 0,

    isReportByVehicleLoading: false,
    reportByVehicle: [],

    isReportByLocationLoading: false,
    reportByLocation: [],

    isReportByPriceHistoryLoading: false,
    reportByPriceHistory: [],

    portals: [],
    arePortalsLoading: false,

    vehicles: [],
    areVehiclesLoading: false,

    regions: [],
    areRegionsLoading: false,

    isError: false,
    msg: '',
    status: '',

    userLogged: false,
    userName: null,
    authError: false,
  },
  getters: {
    APITimeout: () => 1000,
  },
  mutations: {
    changeState: (state, changed) => {
      Object.entries(changed)
        .forEach(([name, value]) => {
          state[name] = value;
        });
    },
  },
  actions: {
    async getUser({ commit }) {
      await axios.get('/api/v1/user/get')
        .then(({ data }) => {
          commit('changeState', { userLogged: true, userName: data.name });
        })
        .catch(() => {});
    },

    async authLogin({ commit }, { login, password }) {
      await axios.post('/api/v1/user/login', { login, password }, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '-1',
        },
      })
        .then(() => { window.location.reload(); })
        .catch(({ response }) => {
          commit('changeState', { authError: response.data.access === 'denied' });
        });
    },

    async logout() {
      await axios.get('/api/v1/user/logout', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '-1',
        },
      }).then(() => { window.location.reload(); });
    },

    async getPortals({ commit, dispatch }) {
      commit('changeState', { arePortalsLoading: true });
      await axios.get('/api/v1/portals')
        .then(({ data }) => {
          commit('changeState', { portals: data });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { arePortalsLoading: false });
    },

    async getVehicles({ commit, dispatch }) {
      commit('changeState', { areVehiclesLoading: true });
      await axios.get('/api/v1/vehicles')
        .then(({ data }) => {
          commit('changeState', { vehicles: data });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { areVehiclesLoading: false });
    },

    async getRegions({ commit, dispatch }) {
      commit('changeState', { areRegionsLoading: true });
      await axios.get('/api/v1/regions')
        .then(({ data }) => {
          commit('changeState', { regions: data });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { areRegionsLoading: false });
    },

    async getReport({ commit, dispatch }, { search, pagination = {} }) {
      commit('changeState', { isReportLoading: true });
      await axios.post('/api/v1/report', {
        ...pickOutParams(search),
        page: pagination.page,
        limit: pagination.limit,
        sorter: pagination.sorter,
      })
        .then(({ data }) => {
          commit('changeState', {
            reportData: data.records,
            reportTotalRecords: data.total_records,
          });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { isReportLoading: false });
    },

    async getReportByVehicle({ commit, dispatch }, { search }) {
      commit('changeState', { isReportByVehicleLoading: true });
      await axios.post('/api/v1/report/vehicle', {
        ...pickOutParams(search),
      })
        .then(({ data }) => {
          commit('changeState', {
            reportByVehicle: data,
          });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { isReportByVehicleLoading: false });
    },

    async getReportByLocation({ commit, dispatch }, { search }) {
      commit('changeState', { isReportByLocationLoading: true });
      await axios.post('/api/v1/report/location', {
        ...pickOutParams(search),
      })
        .then(({ data }) => {
          commit('changeState', {
            reportByLocation: data,
          });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { isReportByLocationLoading: false });
    },

    async getReportByPriceHistory({ commit, dispatch }, { search }) {
      commit('changeState', { isReportByPriceHistoryLoading: true });
      await axios.post('/api/v1/report/history', {
        ...pickOutParams(search),
      })
        .then(({ data }) => {
          commit('changeState', {
            reportByPriceHistory: data,
          });
        })
        .catch((error) => {
          errorHandler(error, dispatch);
        });
      commit('changeState', { isReportByPriceHistoryLoading: false });
    },

    hideAlert({ commit, dispatch }) {
      commit('changeState', { msg: false });
      setTimeout(() => dispatch('setAlert'), 300);
    },

    setAlert({ commit }, { msg = '', isError = false, status = '' } = {}) {
      commit('changeState', { isError, status, msg });
    },
  },
};
